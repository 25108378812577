import PropTypes from "prop-types";
import { Redirect, Route, Switch, useNavigate } from "react-router-dom";
//import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import {
  Card,
  Button,
  Col,
  Row,
  Container,
  Form,
  Alert,
  Dropdown
} from "react-bootstrap";
import LoaderSpinner from "../common/LoaderSpinner";
import React, { useState, useContext } from "react";
import axios from "axios";
import Header from "./Header";
import { v4 as uuidv4 } from "uuid";
import { Url } from '../constant/Url'

function Createuser() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [retypepassword, setRetypePassword] = useState("");
  const [role, setRole] = useState("select role");
  const [company, setCompany] = useState("");
  const [formerror, setFormerror] = useState("");
  const [passworderror, setPassworderror] = useState("");
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [showloader, setShowloader] = useState(false);
  let navigate = useNavigate();
  //let history = useHistory()
  const validate = () => {
    if (
      username.length == 0 ||
      password.length == 0 ||
      retypepassword.length == 0 ||
      role.length == 0 ||
      company.length == 0 ||
      company.length == 0 ||
      phone.length == 0 ||
      fullname.length == 0
    ) {
      setFormerror("Please enter a valid user information");
      return false;
    } else {
      setFormerror("");
      return true;
    }
  };
  const checkpassword = () => {
    if (password == retypepassword) {
      setPassworderror("");
      return true;
    } else {
      setPassworderror("Password does not match");
      return false;
    }
  };

  const Createuser = () => {
    if (validate() && checkpassword()) {
      //call API
      setShowloader(true);
      var data = JSON.stringify({
        "userid": uuidv4(),
        "username": username,
        "password": password,
        "company": "digicides",
        "blocked": 0,
        "deleted": 0,
        "role": role,
        "phone": phone,
        "fullname": fullname
      });

      var config = {
        method: "post",
        url: Url.baseurl + "users/usercreate",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          setShowloader(false);
          if (response.data.status == 201) {
            navigate("/userlist");
            // history.push('/userlist');
          } else {
            setFormerror("USER CREATION FAILED");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
    }
  };
  return (
    <div className="mybody">
      <center>
        <Card className="form-center shadow" style={{ width: "60%" }}>
          <Header />
          <Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Username (Email)"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="text"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicRe-typePassword">
                <Form.Control
                  type="text"
                  placeholder="Re-type Password"
                  value={retypepassword}
                  onChange={(e) => setRetypePassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Fullname"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicRole">

                <Dropdown className="drop">
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {role}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={(e) => setRole('admin')} >Admin</Dropdown.Item>
                    <Dropdown.Item onClick={(e) => setRole('agent')} >Field Agent</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>

              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCompany">
                <Form.Control
                  type="text"
                  placeholder="Company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </Form.Group>
              <Button
                className="btn1"
                type="button"
                disabled={showloader}
                onClick={Createuser}
              >
                CreateUser
              </Button>
              {formerror.length > 0 ? (
                <Alert className="my-4" variant="danger">
                  {formerror}
                </Alert>
              ) : null}
              {passworderror.length > 0 ? (
                <Alert className="my-4" variant="danger">
                  {passworderror}
                </Alert>
              ) : null}
            </Form>
          </Card.Body>
        </Card>
        {showloader && <LoaderSpinner type="Circles" color="#00aced" />}
      </center>
    </div>
  );
}

export default Createuser;
