import React, { useState, useEffect } from "react";
import {
    Card,
    Button,
    Col,
    Row,
    Container,
    Form,
    Table,
} from "react-bootstrap";
import axios from "axios";
import LoaderSpinner from "../common/LoaderSpinner";
import Header from "./Header";
import { Url } from '../constant/Url'
function Userlist() {
    const [userlist, setUserlist] = useState([]);
    const [userlist2, setUserlist2] = useState([]);
    const [showloader, setShowloader] = useState(false);


    const getlist = () => {
        setShowloader(true)
        var data = JSON.stringify({
            role: "admin",
        });

        var config = {
            method: "post",
            url: Url.baseurl + "users/userlist",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setShowloader(false)
                console.log(JSON.stringify(response.data));
                setUserlist(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    //getlist2
    const getlist2 = () => {
        setShowloader(true)
        var data = JSON.stringify({
            role: "agent",
        });

        var config = {
            method: "post",
            url: Url.baseurl + "users/userlist",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setShowloader(false)
                console.log(JSON.stringify(response.data));
                setUserlist2(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };


    useEffect(() => {
        getlist();
    }, []);

    useEffect(() => {
        getlist2();
    }, [userlist]);
    return (
        <div className="mybody">
            <center>
                <Card className="form-center shadow" style={{ width: "60%" }}>
                    <Header />
                    <Card.Body>
                        <Row>
                            <Col>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>User Name</th>
                                            <th>Password</th>
                                            <th>Role</th>
                                            <th>Company</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userlist?.length > 0
                                            ? userlist.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.username}</td>
                                                        <td>{item.password}</td>
                                                        <td>{item.role}</td>
                                                        <td>{item.company}</td>
                                                    </tr>
                                                );
                                            })
                                            : null}

                                        {userlist2?.length > 0
                                            ? userlist2.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.username}</td>
                                                        <td>{item.password}</td>
                                                        <td>{item.role}</td>
                                                        <td>{item.company}</td>
                                                    </tr>
                                                );
                                            })
                                            : null}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {showloader && <LoaderSpinner type="Circles" color="#00aced" />}
            </center>
        </div>
    );
}

export default Userlist;
