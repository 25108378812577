import React, { useState, useContext, useEffect } from "react";
import logo from '../img/syngenta-logo.jpg';
import { Redirect, Route, Switch, useNavigate } from "react-router-dom";
//import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Card, Button, Col, Row, Container, Form, Alert } from "react-bootstrap"
import LoaderSpinner from "../common/LoaderSpinner";
import axios from 'axios';
import { Url } from '../constant/Url'
import { toBePartiallyChecked } from "@testing-library/jest-dom/dist/matchers";

const Admin = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [formerror, setFormerror] = useState('')
    const [loginerror, setLoginerror] = useState('')
    const [showloader, setShowloader] = useState(false)
    let navigate = useNavigate();
    //   let history = useHistory()
    const validate = () => {

        if (email.length == 0 || password.length == 0) {
            setFormerror('Please enter a valid email and password')
            return false
        }
        else {
            setFormerror('')
            return true
        }
    }
    const doLogin = () => {

        if (validate()) {
            /*   var myHeaders = new Headers();
               myHeaders.append("Content-Type", "application/json");
   
               var raw = JSON.stringify({
                   "username": email,
                   "password": password
               });
   
               var requestOptions = {
                   method: 'POST',
                   headers: myHeaders,
                   body: raw,
                   redirect: 'follow'
               };
   
               fetch("https://r8f53j659g.execute-api.ap-south-1.amazonaws.com/Prod/loginuser", requestOptions)
                   .then(response => response.text())
                   .then(result => {
                       console.log(result)
                       let data = result["response"]
                       console.log('data ----', data);
                       if (data["Items"].length > 0) {
                           setLoginerror('')
                           history.push('/dashboard')
                       }
                       else {
                           setLoginerror('Invalid credentials. Check your credentials.')
                       }
   
                   })
                   .catch(error => console.log('error', error));*/
            setShowloader(true)
            var data = JSON.stringify({
                "username": email,
                "password": password
            });

            var config = {
                method: 'post',
                url: Url.baseurl + 'users/userlogin',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    setShowloader(false);
                    let data = response.data.data
                    if (data.length > 0) {
                        setLoginerror('')
                        //store user`
                        let userdata = data[0]
                        localStorage.setItem('user', JSON.stringify(userdata))
                        navigate('/dashboard')
                        //  history.push('/dashboard')
                    }
                    else {
                        setLoginerror('Invalid credentials. Check your credentials.')
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            setFormerror('Please enter a valid email and password')
            alert('fail')
        }
    }
    useEffect(() => {
        localStorage.clear()
    }, [])
    return (
        <div className="mybody">

            <center>
                {showloader && <LoaderSpinner
                    type="Circles"
                    timeout={160000}
                    color="#41b52e"
                />}
                <Card className="form-center shadow" style={{ width: '30%' }}>

                    <Card.Body>
                        <img src={logo} className="mlogo2" />

                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">

                                <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">

                                <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </Form.Group>

                            <Button type="button" disabled={showloader} className="btn-color" onClick={doLogin} >
                                Enter Dashboard
                            </Button>
                            {formerror.length > 0 ? <Alert className="my-4" variant="danger" >{formerror}</Alert> : null}
                            {loginerror.length > 0 ? <Alert className="my-4" variant="danger" >{loginerror}</Alert> : null}
                        </Form>

                    </Card.Body>
                </Card>
            </center>
        </div>
    )
}
export default Admin 