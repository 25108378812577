import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import logo from "../img/syngenta-logo.jpg"

const Head = () => {
    return (
        <Row>
            <Col md={12} lg={12} sm={12} xs={12}>


                <div className="head">
                    <img className="mlogo" src={logo} />
                </div>
            </Col>
        </Row>
    )
}

export default Head