import React from 'react'
import { Card, Button, Col, Row, Container, Form, Navbar, Nav, NavDropdown } from "react-bootstrap"
import { Redirect, Route, Switch, Link } from "react-router-dom";
import logo from '../img/syngenta-logo.jpg';
function Header() {
    let u = JSON.parse(localStorage.getItem('user'))

    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand >  <img src={logo} className="mlogo2" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link ><Link className="mylink" to="/dashboard" label="LOGOUT" >Home</Link></Nav.Link>

                    </Nav>
                </Navbar.Collapse>

                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        Signed in as: <a >{u.role}: {u.username}&nbsp;&nbsp;<Link className='mylink' to="/admin" label="LOGOUT" >LOGOUT</Link></a>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header